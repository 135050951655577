.header {
    background-color: #EEFCFF;
    width: 100%;
    height: auto;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  
    padding: 5px;
  }

 .box {
    display: flex;
    justify-content: center;
    width: 33%;
  }

  .box:first-child {
    align-self: baseline;
  }
  
  .logo {
    cursor: pointer;
    width: 150px;
  }

  h1 {
    font-variant: small-caps;
    text-align: center;
    font-size: 16pt;
    font-weight: bolder;
  }


  a {

    font-weight: 800;
    cursor: pointer;
    color: #2197B1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
  }

  a:hover {
    filter: opacity(70%);
  }