.root {

    width: 400px;
    border: 3px solid #2197B1;
    color: #2197B1;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;

    cursor: pointer;

    transition: filter 0.2s;

}

.root:hover {
    filter: brightness(115%);

}

.title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14pt;
}
