.root {

}

.stageItem:hover .imageBackground {
    transform: scale(1.3);
}

.stagesList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.stageTime {
    display: block;
    color: white;
    font-weight: lighter;
    font-size: 14pt;
}

.imageBackground {
    position: absolute;
    width: inherit;
    height: inherit;
    background-size: contain;
    transition: 0.2s;
}

.stageItem {
    position: relative;
    display: block;
    width: 300px;
    height: 100px;
    border: 1px solid black;
    margin: 4px;

    font-size: 18pt;
    font-weight: bold;
    background-size: contain;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
}

.newBtn {
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.stageItemContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px) brightness(60%);
    transition: 0.2s;
}

.stageItem:hover .stageItemContent {
}

.stageLibelle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}