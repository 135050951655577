

.root {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.header {
    color: white;
    background-color: #2197B1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 50px;
    margin: 0;
}

.accountBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accountBox > p {
    margin-left: 10px;
    font-weight: bold;
    font-size: 14pt;
}

aside {
    color: white;
    margin: 0;
    background-color: #222;
    width: 300px;
    min-height: inherit;
    overflow: hidden;

    padding-top: 20px;
}

li {
    text-decoration: none;
    width: 100%;
    list-style-type:none;
    padding: 10px;
    cursor: pointer;
    transition: 0.1s;
    font-weight: bold;
}


.mainContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
    padding: 0;
    height: fit-content;
}

.content {
    width: 100%;
    padding: 10px;
    min-height: 100vh;


}

.selectedRoute {
    background-color: #2197B1;
    color: white;
    border-right: 2px solid black;
}