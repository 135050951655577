.root {
    
}

.img {
    position: relative;
    width: 100%;
    padding: 10px;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
    transition: 0.2s;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

}

.img:hover {
    cursor: pointer;
    filter: brightness(0.5);
}

.img:hover:after {
    content: "Cliquer pour modifier l'image.";
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(2);
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.formu {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    justify-content: stretch;


}

.formu > div {
    max-width: 90vw;
    display: flex;
    justify-content: stretch;
    align-items: baseline;
    flex-wrap: wrap;

}

.enpause {
    background-color: red;
}

table {
    width: 90%;
}

.stock {
    width: 50%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    justify-content: stretch;
}

.content > * {
    flex-grow: 1;
}

.btnEdit {
    cursor: pointer;
}

.pauseBtn {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.addBtn {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.optionLink {
    text-decoration: underline;
    cursor: pointer;
}

.optionLink:hover p {
    color: darkgray;
}

.topHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
}

.topHead > * {
    flex: 1;
}

.alertMsg {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: darkred;
}

.bottomBtn {
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: end;
    padding: 10px 20px;
    background-color: #FFFFFF99;
    backdrop-filter: blur(5px);
}

.span {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: center;

    margin: 10px 5px;

}

.label {
    display: block;
}