table {
    width: 900px;
    max-width: 90vw;
    border: none;
    border-collapse: collapse;
    background-color: #2197B122;

    overflow-x: auto;

}

td, th {
    padding: 10px;
    text-overflow: wrap;
    overflow-x: hidden;
}

th {
    color: white;
    text-align: left;
}

.table-hoverable {
    cursor: pointer;
}
.table-hoverable:hover {
    filter: brightness(90%);
}

tr:first-child {
    background-color: #2197B1;
}

tr:nth-child(2n) {
    background-color: #2197B122;
}

.prixtotal {
    font-size: 14pt;
    text-align: right;
    width: 100%;
    max-width: 90wh;
    font-weight: bold;
    margin-top: 10px;
    
}

.reduction {
    display: flex;
    justify-content: end;
    text-align: right;
    color: green;
    font-weight: bold;

    margin: 10px 0;
}

.choix {

    margin: 0 auto;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

.panier > div {
    max-width: 90vw;
    overflow-x: auto;
}