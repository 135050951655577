.root {

    margin-top: 100px;

    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 2px solid black;
    padding: 20px;

}

.root > div {
    width: 100%;
}