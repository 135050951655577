.root {

    animation-name: apparition;
    animation-duration: 0.5s;

    margin: 20px;
    padding: 5px;

    min-height: 60px;
    position: fixed;

    top: 0px;


    display: flex;
    align-items: center;
    flex-direction: column;

    justify-content: center;
    width: 90vw;
    max-width: 500px;
    background-color: rgba(255, 0, 0, 0.25);
    backdrop-filter: blur(10px);

    border: 3px solid red;
    border-radius: 10px;

    box-shadow: 0px 0px 20px rgb(147, 147, 147);
}

.root > p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14pt;
    font-weight: bold;
    color: black;
    text-align: center;
}


@keyframes apparition {
    from {
        transform: translateY(-100px);
    }

    to {
        transform: translateY(0);
    }
}



