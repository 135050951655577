.root {
    width: 100%;
    text-align: center;
    margin: 10px;

    border-bottom: 2px solid #EEEEEE;
}

.list {
    display: flex;
    justify-content: center;

    flex-wrap: wrap;

    /* padding-left: 50px; */

}




img {
    width: 100px;
}