.formu {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    justify-content: stretch;


}

.formu > div {
    max-width: 90vw;
    display: flex;
    justify-content: stretch;
    align-items: baseline;
    flex-wrap: wrap;

}

.span {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: center;

    margin: 10px 5px;

}

.label {
    display: block;
}

