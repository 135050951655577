.popup {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    margin: 0 auto;

    width: 600px;
    max-width: 90vw;

    margin-top: 50vh;
    transform: translateY(-50%);

    background-color: #EEFCFF;
    border: 1px solid black;
    box-shadow: 0px 0px 5px black;
    border-radius: 5px;
    align-items: center;

}

.header {
    display: flex;
    background-color: #2197B1;
    color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.closeBtn, .title {
    margin: 10px;
}

.closeBtn {
    font-weight: 800;
    font-size: 21pt;
    cursor: pointer;
}

.background {
    position: fixed;

    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: #00000060;
    backdrop-filter: blur(1px);
}

.documentBox {
    display: flex;
    justify-content: space-between;

    width: 90%;

    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
}

.documentBox > div {
    flex-grow: 1;
}

.libelle {
    font-weight: 700;
    font-size: 14pt;
    color: #2197B1;
    font-variant: small-caps;
}


.description {
    font-weight: normal;
    font-size: 12pt;
}

input[type=checkbox] {
    min-width: 20px;
    min-height: 20px;
    accent-color: #2197B1;
}

.btnChoix {
    margin-top: 20px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}