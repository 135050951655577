.tableHover {
    cursor: pointer;
}

.tableHover:hover {
    background-color: #2197B155;
}

.searchbar {
    width: 90%;
    margin: 0 auto;
}

