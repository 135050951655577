.header {
    display: flex;
    justify-content: space-between;
}

.backButton {
    width: 800px;
    max-width: 90vw;
    text-align: left;
    font-weight: 800;
    font-size: 12pt;
}