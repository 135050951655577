.payDatasBox {
    width: 100px;
    height: 100px;

    background-color: #CCCCCC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 3px;
    margin: 10px;
}
.boxtitle {
    font-weight: bold;
}

.boxvalue {
    font-size: 16pt;
}

.list {
    display: flex;
}