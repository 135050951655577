
.main {
    display: flex;

    align-items: center;
}

.libelle {
    font-weight: bold;
}

.infoRow {
    display: flex;
    justify-content: space-between;
}

.infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 300px;

    border: 1px solid black;
    padding: 10px;

}

.plus {
    font-size: 20pt;
    font-weight: bold;
    margin: 5px;
}

.prixTotal {
    font-size: 22pt;
    font-weight: bold;
}