.root {
    position: relative;
    width: 350px;
    height: 28em;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    background-color: rgb(207, 207, 207);

    border-radius: 5px;

    margin: 10px;
    padding: 0px;
    overflow: hidden;

    background-size: cover;

    color: white;

    box-shadow: 0px 0px 15px #444;
    transition: 0.2s;
    cursor: pointer;

}

.root:hover {
    filter: brightness(115%);
    box-shadow: 0px 0px 20px black;

}

h3, p {
    margin: 0px;
}

.image {
    margin: 0px;
    height: 10em;

    background-color: rgb(0, 0, 32);
    background-size: cover;

}



main {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    text-align: left;
    padding: 10px;
    margin: 0px;

    border-top: 2px solid black;

    object-fit: cover;

    background-color:  rgba(33, 151, 177, 0.2);

    backdrop-filter: blur(15px) brightness(40%);

}

.headerInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 5px;

}

.headerInfo > h3 {
    max-width: 60%;
    font-weight: bold;
}


main > p {
    font-size: 10pt;
    flex-grow: 1;

    margin-top: 10px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

.dispoInfo {
    max-width: 20%;
    font-size: 9pt;

    margin: 3px 0;
    text-align: right;
}

.footerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 10px;
}

.prixInfo {
    font-size: 15pt;
    font-weight: bolder;
}

.jourInfo {
    font-size: 9pt;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    font-size: 8pt;
    font-weight: bold;

    width: fit-content;

    border-radius: 15px;
    border: none;
    background-color: rgba(225, 225, 225, 0.8);
    color: black;
    padding: 4px 7px;
    margin-right: 8px;
    margin-bottom: 8px;

    box-shadow: 0px 0px 5px #222;
}

.infoPanierBulle {
    opacity: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;


    height: fit-content;
    margin-top: -100px;
    text-align: center;

    background-color: black;
    padding: 15px;

    transition: 0.2s;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.root:hover .infoPanierBulle {
    margin-top: 0px;
}

.infoPanierBulle:hover {
    opacity: 100%;
}

.soldout {
    position: absolute;
    width: 350px;
    height: 100%;

    background-color: #000000aa;
    z-index: 2;

    color: white;
    font-weight: bold;
    font-size: 20pt;
    display: flex;
    padding-top: 50px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    cursor: default;
    color: rgb(255, 62, 62);

}

.soldout > p {
    padding: 10px;
    border: 5px solid rgb(255, 62, 62);
    border-radius: 10px;

}

.soldoutcpt {
    width: 80%;
    font-size: 12pt;
    font-weight: 800;
    text-align: justify;
    margin-bottom: 70px;
}

.soldouttext {
    height: fit-content;
    font-weight: 800;
    width: fit-content;
}