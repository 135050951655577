.choix {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

iframe {

    margin: 10px;
    width: 900px;
    max-width: 90vw;
    height: 800px;

    border: 0px;
    overflow: hidden;
}

.choixButtons {
    max-width: 90vw;
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    align-items: center;
}

button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.txt {
    text-align: justify;
    max-width: 90vw;
    width: 500px;
}

.payBox {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 90vh;
    width: 500px;
}

.payBox img {
    align-self: center;
    margin: 30px;
}

.alreadyPaid {
    padding: 3px;
    color: #198419;
    border: 2px solid #198419;
    border-radius: 4px;
    background-color: #19841933;
}