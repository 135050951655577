.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.formu {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    justify-content: stretch;


}

.formu > div {
    max-width: 90vw;
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;

}


.infos {
    width: 400px;
    max-width: 90vw;
    font-size: 14pt;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 3px auto;
}

.infos .value {
    font-weight: 700;
    margin-left: 10px;
}

h2 {
    align-items: center;
    text-align: center;

    font-size: 14pt;
    margin: 0;
    margin-bottom: 20px;
}

.textCenter {
    text-align: center;
}

.infosBox {
    padding: 10px;
    align-items: center;
    width: fit-content;
    border: 1px solid gray;

    border-radius: 5px;
    margin: 10px;
}

.listInfos {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

table {
    margin: 0 auto;
}

.payBox {

}

.msgPopup {
    color: red;
    text-align: center;
    font-weight: bold;
}

.subtitle {
    font-variant: small-caps;
    margin-top: 30px;
}

.toolBox {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #2197B122;
    margin: 10px;
    border-radius: 5px;
}

.toolBox > a {
    margin: 0 15px;
}

.toolBox > a[disabled] {
    color: gray;
    cursor: not-allowed;
}

.addBtn {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.listResaBox {
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

.resaBox {
   display: flex;
   width: 500px;
   min-height: 150px;
   border: 1px solid black; 
   margin: 10px;
   border-radius: 5px;
   overflow: hidden;
   cursor: pointer;
   transition: 0.1s;
   
}

.autorisationField {
    margin-bottom: 5px;
}
.autorisationField > p:first-child {
    font-weight: 800;
    font-size: 10pt;
    color: #2197B1;
}

.autorisationMineur {
    margin-top: 10px;
}

.resaBox:hover {
    filter: brightness(0.8);
}
.stagiaireName {
    font-weight: 800;
}

.resaImg {
    height: inherit;
    width: 150px;
    object-fit: cover;
}

.resaInfos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.resaTitle {
    font-size: 14pt;
    font-weight: bold;
    
}

.resaStagiaire {
    padding: 5px;
    background-color: #2197B122;
    border-radius: 5px;
    margin: 5px 0px;
}