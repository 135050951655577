
.infos {
    width: 400px;
    max-width: 90vw;
    font-size: 14pt;
    display: flex;
    justify-content: space-between;

    margin: 0 auto;
}

.infos .value {
    font-weight: 700;
    margin-left: 10px;
}

.confirmBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: green;
}

.txt {
    width: 500px;
    max-width: 90vw;
    text-align: justify;
}