
.table td {
    border: 1px solid #000 !important;
    max-width: 20px;
    min-height: 20px !important;
    height: 20px;
    cursor: pointer;
}

.table td:hover, th:hover {
    background-color: #2197B1AA;
}

.table th {
    border: 1px solid #000 !important;
    max-width: 20px;
    height: 20px;
    min-height:150px;
    writing-mode: vertical-rl;
    text-align: left;
}


.mailList {
    margin: 20px auto;
}

.mailList th {
    display: flex;
    justify-content: space-between;
}


.date {
    min-width: 100px;
    background-color: #2197B1;
    color: white;
    font-weight: bold;
}