html {
  background-color: #EEFCFF;
  width: 90%;
  overflow-x: hidden;

}


.App {
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  align-items: center;

  padding: 20px;

  
}

.footer {
  height: 50px;
}

.root {
  width: 100vw;
  max-width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:disabled {
  color: gray;
  cursor: not-allowed;
}



.actionButton {
  background-color: #2197B111;
  border: 3px solid #2197B1;
  border-radius: 10px;

  color: #2197B1;
  font-size: 13pt;
  font-weight: bold;

  width: 250px;
  min-height: 50px;

  cursor: pointer;

  margin: 10px;
  transition: 0.2s;

  align-self: center;

}

.actionButtonNegative {
  background-color: #ed5e1211;
  border: 3px solid #ed5e12;
  border-radius: 10px;

  color: #ed5e12;
  font-size: 13pt;
  font-weight: bold;

  width: 250px;
  min-height: 50px;

  cursor: pointer;

  margin: 10px;
  transition: 0.2s;

  align-self: center;

}

.actionButton:hover {
  background-color: #2197B1;
  color: white;

}

.actionButtonNegative:hover {
  background-color: #ed5e12;
  color: white;

}


.actionButton[disabled] {
  filter: opacity(40%);
}

.actionButton[disabled]:hover {
  background-color: #2197B111;
  color: #2197B1;
  
}

select {
  background-color: #2197B111;
  border: 3px solid #2197B1;
  border-radius: 10px;

  color: #2197B1;
  font-size: 13pt;
  font-weight: bold;

  width: 250px;
  min-height: 50px;

  cursor: pointer;

  margin: 10px;
}

.selectInput {
  font-size: 12pt;

  width: inherit;

  min-height: 30px;
  flex-grow: 1;
  padding: 15px;
  border-radius: 10px;
  border: 3px solid #2197B1;
  background-color: #2197B111;
  margin: 3px;
}

.linkButton {
    color: #2197B1;
    font-size: 18pt;
    font-weight: bold;
}


.title {
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  color: #2197B1;
  background-color: #ffffff00;
   /*border: 3px solid #2197B1; */
  font-weight: 800;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}

.explication {
  width: 800px;
  max-width: 90vw;
  max-width: 90%;
  font-size: 13pt;

  border-left: 5px solid #2197B1;
  padding: 5px;
  padding-left: 10px;

  background-color: #2197B122;

  margin: 20px;
  font-weight:500;
}

.error {
  max-width: 40%;
  font-size: 13pt;

  border-left: 5px solid #b12121;
  padding: 5px;
  padding-left: 10px;

  background-color: #b1212122;

  margin: 20px;
  font-weight:500;
}

.simpleInput {
  font-size: 12pt;
  flex-grow: 1;

  max-height: 30px;
  min-height: 30px;

  padding: 10px;
  border-radius: 10px;
  border: none;
  border: 3px solid #2197B1;
  background-color: #2197B111;
  margin: 2px;

}

.simpleInput:disabled {
  border-color: gray;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 2px;
  margin-left: 5px;
}

.stepper {
  width: 30%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .stepper {
    display: none;
  }

  .logo {
    width: 50px;
  }
}


