.stagelist {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
}

.header {
    max-width: 800px;
    margin: 0 auto;
}

.header h2{
    display: flex;
    align-items: center;
    
    justify-content: space-between;

    margin: 0 auto;
}

.changeDateBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toolBox {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #2197B122;
    margin: 10px;
    border-radius: 5px;
}

.toolBox > a {
    margin: 0 15px;
}

.toolBox > a[disabled] {
    color: gray;
    cursor: not-allowed;
}
