.span {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: center;

    margin: 10px 5px;

}

.label {
    display: block;
}

.error {
    margin-left: 5px;
    color: red;
    height: 10px;
}

.label p {
    display: inline;
    font-weight: lighter;
}


