.root {

    width: 300px;
    margin: 10px;

    background-color: #2297B122;
    
    padding: 5px;

    min-height: 300px;
    border-radius: 3px;
}

.libelle {
    color: black;
    padding: 0px;
    margin: 5px;
}



.item:nth-child(2n) {
    background-color: #00000011;
}

.insideBar {
    background-color: #00000066;
    height: 100%;
    width: 0%;
}

.outboxBar {
    width: 80%;
    height: 15px;

    margin: 5px;

    border: 2px solid black;
}

.nbInfos {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.item {
    cursor: pointer;
}

.item:hover {
    text-decoration: underline;
}