.popup {
    position: fixed;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    width: 600px;
    max-width: 90vw;

    background-color: #EEFCFF;
    border: 1px solid black;
    box-shadow: 0px 0px 5px black;
    border-radius: 5px;
    align-items: center;

    padding: 10px 0px;

}


.checkbox {
    width: 30px;
    height: 30px;
    margin: 10px;
    border: 2px solid black;

}

.high {
    animation: highlight-check 1s infinite;
}



@keyframes highlight-check {
    0% {
        box-shadow: 0px 0px 5px 0px red;
    }
    50% {
        box-shadow: 0px 0px 5px 5px red;
    }
    100% {
        box-shadow: 0px 0px 5px 0px red;
    }
    
}



.background {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 50%;
    backdrop-filter: blur(50px);
}

.documentBox {
    display: flex;
    justify-content: space-between;

    width: 90%;

    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;

}

.documentBox > div {
    flex-grow: 1;
}

.libelle {
    display: inline;
    font-weight: 700;
    font-size: 14pt;
    color: #2197B1;
    font-variant: small-caps;
}


.description {
    font-weight: normal;
    font-size: 12pt;
}

input[type=checkbox] {
    min-width: 20px;
    min-height: 20px;
    accent-color: #2197B1;
}

.btnChoix {
    margin-top: 20px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.mentionsList {
    max-width: 90%;
    margin-left: 0px;
    padding-left: 0px;
}

.mentionItem {
    text-align: justify;
    font-weight: 400;
    font-size: 11pt;
    list-style: circle inside;
}

.mentionItem:hover {

}

.downloadBtn {
    font-weight: 400;
    font-size: 10pt;
}